<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Objet / Sous-objet" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter un objet</div>
              </v-row>
            </v-card-title>           

            <!-- application -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">application</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    v-model="selectedApp"
                    :items="apps"
                    item-text="label"
                    return-object
                    placeholder="Choisir une application"
                    clearable
                    class="ma-0 pa-0"
                    no-data-text="aucune application"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>    

            <!-- service -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">service</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    v-model="selectedService"
                    :items="services"
                    item-text="digitalName"
                    return-object
                    placeholder="Choisir un service"
                    clearable
                    class="ma-0 pa-0"
                    no-data-text="aucun service"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>    

             <!-- le label -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">label</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le label"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
       
            <!-- Les sous-objets -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">les sous-objets</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <!-- Affichage des sous-objets à associé -->
                  <div v-for="(sub, i) in associateSubObject" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <v-text-field 
                        placeholder="Le nom du sous-objet"
                        dense
                        v-model="sub.label">
                      </v-text-field>
                      <v-btn
                        icon
                        color="primary"
                        @click="removeAssociateSubObject(sub)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <!-- Affichage de la liste des sous-objets associables -->
                  <div>
                    <v-row no-gutters align="start" justify="space-between">
                       <v-text-field 
                        placeholder="Le nom du sous-objet"
                        dense
                        v-model="subObject"                        
                        v-on:keyup.enter="addSubObject()">
                      </v-text-field>
                      <v-btn
                        color="primary"
                        icon
                        @click="addSubObject()"
                        :disabled="!subObject"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>           

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="mx-2 my-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { TicketingObjectSubobjectService } from "@/service/conf/ticketing_object_subobject_service.js";
import { ApplicationService } from "@/service/dictionary/applications_service.js";
import ServicesService from "@/service/sfr/services_service.js";
import { TicketingStatusService } from "@/service/conf/ticketing_status_service.js";

import * as exceptions from "@/service/exception_to_message.js";

const _SERVICE_UNDEFINED = { id: "undefined", digitalName: "PAS DE SERVICE" };

export default {
  name: "AddTicketingObjectSubobject",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,
      serviceService: null,
      serviceApps : null,
      serviceTicketingStatus: null,

      /** le label */
      label: null,
      
      /** l'application sélectionné */
      selectedApp: null,
      /** la liste des applications */
      apps: [],

      /** le service sélectionné */
      selectedService: null,
      /** la liste des services */
      services: [],
      

      /** La liste des sous-objets associé */
      associateSubObject: [],

      /** Le sous-objet à ajouté */
      subObject: null,

    };
  },
  methods: {
    /** Chargement des datas de la vue */
    async load() {
      try {
        this.loading = true;

        // Récupère la liste des applications dans les dico
        this.apps = await this.serviceApps.getAll();

        // Récupération de tous les service
        this.services = await this.serviceService.getAllServices();
        this.services.push(_SERVICE_UNDEFINED);

        this.associateSubObject = [];

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " + (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    async save() {
      // enregistrement 
      if (this.entityHasChanged) {
        try {
          let entity = {
            label: this.label,
          };

          let appHasStatus = true;
          // Récupération de l'ensemble des statut de l'app
          let statusOfApp = await this.service.getAllStatusOfApplication(this.selectedApp.id);
          // Si l'app n'a pas de statut, on met le marqueur à false
          if (statusOfApp.length == 0) {
            appHasStatus = false;
          }

          // Sauvegarde de l'objet
          let object = await this.service.createObject(this.selectedApp.id, this.selectedService.id, entity); 

          // Boucle pour la sauvegarde des sous-objets
          for (let i = 0; i < this.associateSubObject.length; i++) {
            let sub = {
              label: this.associateSubObject[i].label,
            }
            await this.service.createSubObject(this.selectedApp.id, this.selectedService.id, object.id, sub); 
          }

          // L'application n'as pas de statut mais un nouvel objet, on doit donc lui associer les status par défaut.
          if (!appHasStatus) {
            // console.log("as statut");
            // Récupération de l'ensemble des status par défaut
            let defaultStatus = await this.serviceTicketingStatus.getAll();

            for (let status of defaultStatus) {
              await this.service.associateStatutToApp(this.selectedApp.id, status.id);
            }
          }

          
          this.$router.go(-1);
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de l'objet : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
    },

    /** Suppression d'un sous-objet */
    removeAssociateSubObject(subObject) {
      this.associateSubObject.splice(
        this.associateSubObject.find((s) => s.label === subObject.label),
        1
      );
    },
    /** Ajouter le sous-objet entré */
    addSubObject() {
      this.associateSubObject.push({
        label: this.subObject,
      });

      this.subObject = null;
    },
  },
  computed: {
    canSave() {
      try {
        if (!this.label || this.label == "") {
          return false;
        }
        if (this.selectedApp == null) {
          return false;
        }
        if (this.selectedService == null) {
          return false;
        }
        
        let changed = false;

        if (this.entityHasChanged) {
          changed = true;
        }

        return changed;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    
    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (this.label) {
        changed = true;
      }
      if (this.selectedApp) {
        changed = true;
      }
      if (this.selectedService) {
        changed = true;
      }

      return changed;
    },

    /** Retourne true si le sous-objet est déjà existant dans la liste associée à l'objet */
    subobjectisNotUnique(value) {
      return this.associateSubObject.includes(value);
    },

    
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new TicketingObjectSubobjectService(this.$api.getTicketingObjectSubobject());
    this.serviceService = new ServicesService(this.$api);
    this.serviceApps = new ApplicationService(this.$api.getApplicationApi());
    this.serviceTicketingStatus = new TicketingStatusService(this.$api.getTicketingStatus());
    
    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>